import { FC, useEffect, useRef, useState } from "react";
import { videoBlobManager } from "../../app/blobManager";
import {
  cleanUpMediaRecorder,
  getVideoMediaRecorder,
} from "../../helpers/mediaRecorderHelpers";
import { useAppDispatch } from "../../hooks/hook";
import { uploadVideoChunk } from "./videoRecordingSlice";

interface VideoRecorderProps {
  isCalling: boolean;
  shouldRecordVideo: boolean;
  deviceId: string | undefined;
}

// How often to create a new video chunk.
const CHUNK_DURATION_MS = 10000;

export const VideoRecorder: FC<VideoRecorderProps> = ({
  isCalling,
  shouldRecordVideo,
  deviceId,
}) => {
  const dispatch = useAppDispatch();

  // Refs so we can maintain state across renders.
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const lastChunkEndTimeRef = useRef<number>(0);
  const [error, setError] = useState<string | null>(null);

  // Will fire every CHUNK_DURATION_MS
  const onDataAvailableHandler = (event: BlobEvent) => {
    if (event.data.size > 0) {
      const currentTime = Date.now();
      const chunkStartTime = lastChunkEndTimeRef.current;
      lastChunkEndTimeRef.current = currentTime;

      const fileName = `${chunkStartTime}_${currentTime}.webm`;

      // Store blob in memory
      videoBlobManager.addBlob(fileName, {
        blob: event.data,
      });

      // Dispatch to upload to S3
      dispatch(
        uploadVideoChunk({
          fileName: fileName,
        })
      );
    }
  };

  const startRecording = async () => {
    // Check if already recording
    if (mediaRecorderRef.current?.state === "recording") {
      console.warn("Recording already in progress");
      return;
    }

    if (!deviceId) {
      console.warn("No device ID provided");
      return;
    }

    try {
      const mediaRecorder = await getVideoMediaRecorder(deviceId);
      streamRef.current = mediaRecorder.stream;
      mediaRecorderRef.current = mediaRecorder;

      // Set up data available handler
      mediaRecorder.ondataavailable = onDataAvailableHandler;

      // For the first chunk, set the reference time to now.
      lastChunkEndTimeRef.current = Date.now();

      mediaRecorder.start(CHUNK_DURATION_MS);
    } catch (err) {
      console.error("Error starting video recording:", err);
      setError(
        err instanceof Error ? err.message : "Failed to start recording"
      );
    }
  };

  const stopRecording = () => {
    cleanUpMediaRecorder(mediaRecorderRef.current);
    mediaRecorderRef.current = null;
    streamRef.current = null;
  };

  // Handle recording start/stop based on isCalling prop
  useEffect(() => {
    if (!shouldRecordVideo || !deviceId) {
      return;
    }

    if (isCalling) {
      startRecording();
    } else {
      stopRecording();
    }

    // Stop recording on unmount
    return () => {
      stopRecording();
    };
  }, [isCalling, shouldRecordVideo]);

  if (error) {
    return (
      <div className="text-red-500">
        Failed to start video recording: {error}
      </div>
    );
  }

  return null;
};
