import { Dialog } from "@headlessui/react";
import { CameraIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import { HandlerOf, InterviewDeviceTypesEnum } from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, Modal } from "ui";
import { CameraPreview } from "../voiceAgentInterview/cameraPreview";
import { DeviceSelect } from "./microphoneSelect";

interface InterviewDeviceModalProps {
  deviceType: InterviewDeviceTypesEnum;
  currentDeviceId: string | undefined;
  onUserChangeDevice: (newDeviceId: string) => void;
  devices: MediaDeviceInfo[];
  setDevices: HandlerOf<MediaDeviceInfo[]>;
  setDeviceError: (err: Error | null) => void;
  fetchDevices: () => void;
}

export const InterviewDeviceModal: FC<InterviewDeviceModalProps> = ({
  deviceType,
  setDeviceError,
  currentDeviceId,
  onUserChangeDevice,
  devices,
  setDevices,
  fetchDevices,
}) => {
  const [deviceId, setDeviceId] = useState<string | undefined>(currentDeviceId);

  const DeviceIcon =
    deviceType === InterviewDeviceTypesEnum.MICROPHONE
      ? MicrophoneIcon
      : CameraIcon;

  return (
    <Modal isOpen={true}>
      <div className="mb-4">
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
          <DeviceIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <Dialog.Title
          as="h3"
          className="text-base font-semibold leading-6 text-gray-700"
        >
          Change {deviceType}
        </Dialog.Title>
        <div className="mt-2 text-center">
          <p className="text-sm text-gray-500">
            Your session is paused. You can choose a new {deviceType}, and then
            pick up from the last question.
          </p>
        </div>
        <div className="mt-4 min-w-[200px]">
          <DeviceSelect
            devices={devices}
            setDevices={setDevices}
            onSelectDevice={setDeviceId}
            currentDeviceId={deviceId}
            setDeviceError={setDeviceError}
            fetchDevices={fetchDevices}
          />
        </div>

        {deviceType === InterviewDeviceTypesEnum.CAMERA && deviceId && (
          <div className="mt-4">
            <CameraPreview width={170} deviceId={deviceId} />
          </div>
        )}

        <div className="mt-5 sm:mt-6 flex justify-center">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label={`Update ${deviceType}`}
            onClick={() => {
              if (deviceId) onUserChangeDevice(deviceId);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
