export const getAudioMediaRecorder = async (
  audioDeviceId: string | undefined
) => {
  return navigator.mediaDevices
    .getUserMedia({
      audio: audioDeviceId ? { deviceId: { exact: audioDeviceId } } : true,
    })
    .then((stream) => {
      // Now that we have audio permissions, setup recording
      // Check for MIME type support
      const possibleTypes = ["audio/webm", "audio/mp4"];
      let mimeType = possibleTypes.find((type) =>
        MediaRecorder.isTypeSupported(type)
      );

      if (!mimeType) {
        throw new Error("No supported MIME type found for MediaRecorder");
      }

      // Now that we have audio permissions, setup recording
      return new MediaRecorder(stream, { mimeType });
    })
    .catch((err) => {
      throw err;
    });
};

export const getVideoMediaRecorder = async (deviceId: string) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: { exact: deviceId },
        width: { ideal: 320, max: 480 },
        height: { ideal: 240, max: 360 },
        frameRate: { ideal: 15 },
      },
    });

    // Check for MIME type support
    const mimeType = "video/webm;codecs=vp8,opus";
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      throw new Error("Video MIME type not supported");
    }

    return new MediaRecorder(stream, {
      mimeType,
      videoBitsPerSecond: 250000,
    });
  } catch (err) {
    throw err;
  }
};

export const cleanUpMediaRecorder = (mediaRecorder: MediaRecorder | null) => {
  // Stop and release the current MediaRecorder and audio tracks if they exist
  if (mediaRecorder) {
    if (
      mediaRecorder.state === "recording" ||
      mediaRecorder.state === "paused"
    ) {
      mediaRecorder.stop();
    }
    mediaRecorder.stream.getTracks().forEach((track) => track.stop());
  }
};
