import { Dialog } from "@headlessui/react";
import {
  InterviewPublic,
  isRecruitingModeProject,
  isValidEmail,
} from "app-types";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button, ButtonVariantsEnum, Input, Link, Modal } from "ui";
import { createAxiosInstance } from "../../api/axiosConfig";
import { getSupportEmail } from "../../helpers/utilities";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  initiateInterviewForProjectLink,
  selectProjectLinkState,
} from "../projectLink/projectLinkSlice";
import { InterviewDataResponse } from "../transcriptFragments/transcriptFragmentsSlice";
import { InterviewWelcomePoints } from "./interviewWelcomePoints";

// Set options on the window for `react-google-recaptcha`
(window as any).recaptchaOptions = {
  useRecaptchaNet: true,
  enterprise: true,
};

interface InterviewOnboardingProps {
  onClickStartInterview: (interview: InterviewPublic) => void;
  isResuming: boolean;
  interview: InterviewPublic | null;
}

/*
 * This component has a few different states:
 * - Microphone setup:
 *   - Audio permissions not yet requested: shows a welcome message with button to request permissions
 *   - Asking for audio permissions: shows a banner and loading button (isRequestingMicrophoneAccess)
 *   - Audio permissions rejected (microphoneError)
 *   - Audio permissions granted: allows user to pick their mic and then start session
 * - Questions preview: shows a preview of the questions and allows user to start session or try test question
 */

const START_INTERVIEW_RECAPTCHA_ACTION_NAME = "start_interview";

export const InterviewOnboarding: FC<InterviewOnboardingProps> = (props) => {
  const { isResuming, interview } = props;
  const contactFirstName = interview ? interview.contact.first_name : null;

  const dispatch = useAppDispatch();
  const [isVerifyingRecaptcha, setIsVerifyingRecaptcha] = useState(false);
  const [
    shouldShowRecaptchaFallbackModal,
    setShouldShowRecaptchaFallbackModal,
  ] = useState(false);

  // User-supplied email for project links
  const [email, setEmail] = useState("");
  const [isEmailPrefilled, setIsEmailPrefilled] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);

  const { status: projectLinkStatus, project } = useAppSelector(
    selectProjectLinkState
  );

  const supportEmail = getSupportEmail();

  const isRecruitingMode = project && isRecruitingModeProject(project);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const emailParam = urlParams.get("e");

    if (emailParam && isValidEmail(emailParam, true)) {
      setEmail(emailParam);
      setIsEmailPrefilled(true);
    } else if (interview && interview.contact.email) {
      setEmail(interview.contact.email);
    }
  }, []);

  const completeOnboarding = async () => {
    if (!interview)
      throw new Error("Cannot complete onboarding without interview");

    return props.onClickStartInterview(interview);
  };

  const verifyRecaptcha = async () => {
    try {
      setIsVerifyingRecaptcha(true);

      // Make sure reCAPTCHA has loaded - in practical scenarios, this should never
      // be the case unless you are a bot.
      if (!executeRecaptcha) {
        console.error("reCAPTCHA not yet available");
        return;
      }

      // Create a token to be verified server-side for this action.
      const recaptchaToken = await executeRecaptcha(
        START_INTERVIEW_RECAPTCHA_ACTION_NAME
      );

      const axiosInstance = createAxiosInstance();
      await axiosInstance.post("/verify-recaptcha", {
        token: recaptchaToken,
        action: START_INTERVIEW_RECAPTCHA_ACTION_NAME,
      });
      setIsVerifyingRecaptcha(false);

      return true;
    } catch (error) {
      setIsVerifyingRecaptcha(false);
      // If a server failure occured, don't block the flow.
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status === 500
      ) {
        console.error("Server error occurred during reCAPTCHA verification");
      } else {
        // If they failed recaptcha, fallback to V2 verification (checkbox)
        console.error(
          "Failed to verify reCAPTCHA, falling back to V2 verification"
        );
        setShouldShowRecaptchaFallbackModal(true);
      }

      return false;
    }
  };

  const initiateInterview = async () => {
    try {
      const result = await dispatch(
        initiateInterviewForProjectLink({
          email,
          first_name: isRecruitingMode ? firstName : null,
          last_name: isRecruitingMode ? lastName : null,
          phone_number: null,
        })
      );
      const response = result.payload as InterviewDataResponse;
      // Type casting - can be InterviewDataResponse or EmailValidationRequiredResponse so we need the interview check
      if (response.interview) {
        props.onClickStartInterview(response.interview);
      }
    } catch (err) {
      console.error("Error initiating interview", err);
    }
  };

  const hasValidInputs = () => {
    let isValid = true;

    if (!isValidEmail(email, true)) {
      setEmailError(
        "Please enter a valid email address. Aliases are not supported."
      );
      isValid = false;
    } else {
      setEmailError(null);
    }

    if (isRecruitingMode) {
      if (firstName.trim().length < 2 || firstName.trim().length > 50) {
        setFirstNameError("First name must be between 2 and 50 characters.");
        isValid = false;
      } else {
        setFirstNameError(null);
      }

      if (lastName.trim().length < 2 || lastName.trim().length > 50) {
        setLastNameError("Last name must be between 2 and 50 characters.");
        isValid = false;
      } else {
        setLastNameError(null);
      }
    }

    return isValid;
  };

  const onSubmit = async () => {
    if (!hasValidInputs()) return;

    const isVerified = await verifyRecaptcha();

    if (!isVerified) return;

    initiateInterview();
  };

  const maybeRenderRecaptchaFallbackModal = () => {
    if (!shouldShowRecaptchaFallbackModal) return null;

    return (
      <Modal isOpen={true}>
        <div className="flex flex-col items-center space-y-2">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-700"
          >
            Please complete the reCAPTCHA to continue
          </Dialog.Title>

          <ReCAPTCHA
            sitekey={import.meta.env.VITE_RECAPTCHA_V2_SITE_KEY}
            onChange={async () => {
              initiateInterview();
              setShouldShowRecaptchaFallbackModal(false);
            }}
          />
        </div>
      </Modal>
    );
  };

  const renderCTASection = () => {
    if (!interview) {
      return (
        <form // Wrapping in form component ensures that "Go" button on mobile keyword submits inputted text
          onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission via HTTP
            onSubmit(); // Handle submit ourselves
          }}
        >
          <div className="flex flex-col space-y-4">
            <div>
              <div className="text-sm font-medium text-left">
                {`Email${
                  project?.incentive_quantity
                    ? ". Your gift card will be delivered to this address."
                    : ""
                }`}
              </div>
              <div className="text-left w-full sm:w-[336px] mt-2">
                <Input
                  value={email}
                  onChange={(evt) => {
                    setEmailError(null);
                    setEmail(evt.target.value);
                  }}
                  autoComplete="email"
                  placeholder="amy@acme.com"
                  isDisabled={Boolean(interview) || isEmailPrefilled}
                  errorDescription={emailError || undefined}
                  autoFocus={!isEmailPrefilled}
                  description=""
                />
              </div>
            </div>
            {isRecruitingMode && (
              <div className="flex space-x-4 w-full">
                <div className="flex-1 sm:max-w-40">
                  <div className="text-sm font-medium text-left">
                    First name
                  </div>
                  <div className="text-left mt-2">
                    <Input
                      value={firstName}
                      onChange={(evt) => {
                        setFirstNameError(null);
                        setFirstName(evt.target.value);
                      }}
                      autoComplete="given-name"
                      placeholder="John"
                      errorDescription={firstNameError || undefined}
                      description=""
                    />
                  </div>
                </div>
                <div className="flex-1 sm:max-w-40">
                  <div className="text-sm font-medium text-left">Last name</div>
                  <div className="text-left mt-2">
                    <Input
                      value={lastName}
                      onChange={(evt) => {
                        setLastNameError(null);
                        setLastName(evt.target.value);
                      }}
                      autoComplete="family-name"
                      placeholder="Doe"
                      errorDescription={lastNameError || undefined}
                      description=""
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-center space-x-6 pt-2">
              <Button
                onClick={onSubmit}
                label={"Continue"}
                variant={ButtonVariantsEnum.Primary}
                isLoading={
                  isVerifyingRecaptcha || projectLinkStatus === "loading"
                }
              />
            </div>
          </div>
        </form>
      );
    }

    return (
      <div className="flex flex-col space-y-4">
        <div className="flex justify-center space-x-6 pt-2">
          <Button
            onClick={() => {
              completeOnboarding();
            }}
            label={isResuming ? "Resume" : "Continue"}
            variant={ButtonVariantsEnum.Primary}
            isLoading={isVerifyingRecaptcha || projectLinkStatus === "loading"}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="p-8">
      {project?.logo_url ? (
        <div className="flex mb-4 justify-center">
          <img
            src={project.logo_url}
            alt="Company logo"
            className="max-w-[170px] max-h-[50px]"
          />
        </div>
      ) : null}
      <h1 className="text-3xl font-bold mb-4 text-gray-800">
        {`Welcome${isResuming ? " back" : ""}${
          contactFirstName ? ", " + contactFirstName : ""
        }`}
      </h1>

      <InterviewWelcomePoints
        project={project}
        isResuming={isResuming}
        questions={interview?.questions || project?.questions || []}
      />
      <div className="flex flex-col text-center mt-4">{renderCTASection()}</div>

      <div className="text-xs text-center w-full mt-8 sm:mt-0 sm:absolute sm:bottom-2 sm:left-0 text-slate-500 sm:text-slate-300">
        {
          "This site is protected by reCAPTCHA Enterprise. Need help? Email us at "
        }
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        {"."}
      </div>
      {maybeRenderRecaptchaFallbackModal()}
    </div>
  );
};
