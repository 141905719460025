import { configureStore } from "@reduxjs/toolkit";
import complexAnswersReducer from "../features/complexAnswers/complexAnswersSlice";
import projectLinkReducer from "../features/projectLink/projectLinkSlice";
import transcriptFragmentsReducer from "../features/transcriptFragments/transcriptFragmentsSlice";
import videoRecordingReducer from "../features/voiceAgentInterview/videoRecordingSlice";

const store = configureStore({
  reducer: {
    transcriptFragments: transcriptFragmentsReducer,
    projectLink: projectLinkReducer,
    complexAnswers: complexAnswersReducer,
    videoRecording: videoRecordingReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
