import {
  EntityState,
  PayloadAction,
  Selector,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { TranscriptFragmentSaveStatusEnum as SaveStatusEnum } from "app-types";
import { createAxiosInstance } from "../../api/axiosConfig";
import { videoBlobManager } from "../../app/blobManager";
import { RootState } from "../../app/store";

/*
 * Types
 */

interface PresignedVideoUploadUrl {
  url: string;
  fields: Record<string, string>; // Stores special S3 fields needed for the upload to be valid.
}

export interface VideoChunk {
  fileName: string; // Will be of format "{"start_time"}_{"end_time"}.webm"
  saveStatus: SaveStatusEnum;
  error: string | null;
}

export interface VideoRecordingState {
  chunks: EntityState<VideoChunk>;
  alpharunCallId: string | null;
  presignedVideoUploadUrl: PresignedVideoUploadUrl | null;
}

// Create the adapter
export const videoChunksAdapter = createEntityAdapter<VideoChunk>({
  selectId: (chunk) => chunk.fileName,
});

const initialState: VideoRecordingState = {
  chunks: videoChunksAdapter.getInitialState(),
  alpharunCallId: null,
  presignedVideoUploadUrl: null,
};

/*
 * Slice.
 */

const videoRecordingSlice = createSlice({
  name: "videoRecording",
  initialState,
  reducers: {
    setAlpharunCallId: (state, action: PayloadAction<string>) => {
      state.alpharunCallId = action.payload;
    },
    clearPresignedVideoUploadUrl: (state) => {
      state.presignedVideoUploadUrl = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresignedVideoUploadUrl.fulfilled, (state, action) => {
        state.presignedVideoUploadUrl = action.payload;
      })
      .addCase(uploadVideoChunk.pending, (state, action) => {
        const { fileName } = action.meta.arg;
        videoChunksAdapter.addOne(state.chunks, {
          fileName,
          saveStatus: SaveStatusEnum.SAVING,
          error: null,
        });
      })
      .addCase(uploadVideoChunk.fulfilled, (state, action) => {
        const { fileName } = action.meta.arg;
        videoChunksAdapter.updateOne(state.chunks, {
          id: fileName,
          changes: {
            saveStatus: SaveStatusEnum.SAVED,
            error: null,
          },
        });

        // Clean up blob from memory after successful upload
        videoBlobManager.removeBlob(fileName);
      })
      .addCase(uploadVideoChunk.rejected, (state, action) => {
        const { fileName } = action.meta.arg;
        videoChunksAdapter.updateOne(state.chunks, {
          id: fileName,
          changes: {
            saveStatus: SaveStatusEnum.ERROR,
            error: action.payload || action.error.message || null,
          },
        });

        // Clean up blob from memory if we failed to upload
        videoBlobManager.removeBlob(fileName);
      });
  },
});

export const {
  setAlpharunCallId,
  clearPresignedVideoUploadUrl: clearPresignedPost,
} = videoRecordingSlice.actions;
export default videoRecordingSlice.reducer;

/*
 * Async actions
 */

export const getPresignedVideoUploadUrl = createAsyncThunk<
  PresignedVideoUploadUrl,
  void,
  { rejectValue: string }
>("videoRecording/getPresignedVideoUploadUrl", async (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const alpharunCallId = state.videoRecording.alpharunCallId;
  const token = state.transcriptFragments.token;

  if (!alpharunCallId) throw new Error("No alpharun call ID found");

  try {
    const axiosInstance = createAxiosInstance(token);
    const { data } = await axiosInstance.post("interview/video-upload-url", {
      alpharun_call_id: alpharunCallId,
    });

    return {
      url: data.url,
      fields: data.fields,
    };
  } catch (err: any) {
    console.error("Error getting presigned post URL:", err);
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const uploadVideoChunk = createAsyncThunk<
  void,
  { fileName: string },
  { rejectValue: string }
>("videoRecording/uploadChunk", async (payload, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  let presignedPost = state.videoRecording.presignedVideoUploadUrl;

  const blobInfo = videoBlobManager.getBlob(payload.fileName);
  if (!blobInfo) throw new Error("No video blob found for chunk");

  try {
    // If we don't have a presigned post URL yet, get one
    if (!presignedPost) {
      presignedPost = await thunkAPI
        .dispatch(getPresignedVideoUploadUrl())
        .unwrap();
    }

    // Create form data with required fields and chunk
    const formData = new FormData();
    Object.entries(presignedPost.fields).forEach(([key, value]) => {
      if (key !== "key") {
        formData.append(key, value);
      }
    });

    // Calculate basePath from the key field when needed
    const basePath = presignedPost.fields.key.replace("/${filename}", "");
    // Set our custom key with the timestamp format
    formData.append("key", `${basePath}/${payload.fileName}`);
    formData.append("Content-Type", "video/webm");
    formData.append("file", blobInfo.blob);

    // Use axios instance with retries, but no auth token since this is a direct S3 upload
    const axiosInstance = createAxiosInstance(null, {
      enableRetries: true,
    });

    await axiosInstance.post(presignedPost.url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err: any) {
    console.error("Error uploading video chunk:", err);

    // Add Sentry error tracking
    Sentry.captureException(err, {
      tags: {
        fileName: payload.fileName,
        type: "video_upload_error",
      },
      extra: {
        presignedUrlExists: !!presignedPost,
      },
    });

    if (err.message.includes("403") || err.message.includes("expired")) {
      thunkAPI.dispatch(clearPresignedPost());
    }

    return thunkAPI.rejectWithValue(err.message);
  }
});

// Selectors
const selectVideoChunksState = (state: RootState) =>
  state.videoRecording.chunks;

export const selectIsSavingVideoChunks: Selector<RootState, boolean> =
  createSelector(selectVideoChunksState, (chunks) =>
    Object.values(chunks.entities).some(
      (chunk): chunk is VideoChunk =>
        chunk !== undefined && chunk.saveStatus === SaveStatusEnum.SAVING
    )
  );
