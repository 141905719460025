import { HandlerOf } from "app-types";
import { FC } from "react";
import { Select } from "ui";

interface DeviceSelectProps {
  devices: MediaDeviceInfo[];
  setDevices: HandlerOf<MediaDeviceInfo[]>;
  onSelectDevice: (deviceId: string) => void;
  setDeviceError: HandlerOf<Error | null>;
  currentDeviceId?: string;
  fetchDevices: () => void;
}

export const DeviceSelect: FC<DeviceSelectProps> = ({
  devices,
  onSelectDevice,
  currentDeviceId,
  fetchDevices,
}) => {
  // Use the selected device or default device
  const currentDevice =
    devices.find((m) => m.deviceId === currentDeviceId) ||
    devices.find((m) => m.deviceId === "default") || // Chrome-only "default"
    (devices.length > 0 && devices[0]);

  return (
    <Select
      options={devices.map((m) => ({
        name: m.label,
      }))}
      onChange={(value) => {
        const device = devices.find((m) => m.label === value.name);
        if (device) onSelectDevice(device.deviceId);
      }}
      // Fallback to an empty selection to avoid a "A component
      // is changing from uncontrolled to controlled" warning
      currentSelection={
        currentDevice ? { name: currentDevice.label } : { name: "" }
      }
      onClick={() => {
        // Reload devices on open/close
        fetchDevices();
      }}
    />
  );
};
