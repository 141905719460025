export class BlobManager<T extends { blob: Blob }> {
  private blobInfos: Record<string, T>;

  constructor() {
    this.blobInfos = {};
  }

  addBlob(id: string, blobInfo: T) {
    this.blobInfos[id] = blobInfo;
  }

  getBlob(id: string) {
    return this.blobInfos[id];
  }

  removeBlob(id: string) {
    delete this.blobInfos[id];
  }
}

export type AudioBlobInfo = {
  blob: Blob;
  mimeType: string;
};

// Stores audio blobs in memory for each transcript fragment
export const audioBlobManager = new BlobManager<AudioBlobInfo>();

export type VideoBlobInfo = {
  blob: Blob;
};

// Store video blobs in memory for each chunk
export const videoBlobManager = new BlobManager<VideoBlobInfo>();
