import { ActivityTypesEnum } from "app-types";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../../api/axiosConfig";
import { debounce } from "../../helpers/utilities";
import { selectInterviewToken } from "../transcriptFragments/transcriptFragmentsSlice";

interface WindowActivityLoggerProps {
  shouldForceFullscreen: boolean;
}

export const WindowActivityLogger: FC<WindowActivityLoggerProps> = ({
  shouldForceFullscreen,
}) => {
  const interviewToken = useSelector(selectInterviewToken);

  const axiosInstance = createAxiosInstance(interviewToken);
  const logWindowActivity = debounce(async (type: ActivityTypesEnum) => {
    const timestamp = new Date().toISOString();
    try {
      await axiosInstance.post("/interview/activity", {
        type,
        timestamp,
      });
    } catch (error) {
      console.error(`Failed to log window ${type} activity:`, error);
    }
  }, 200);

  useEffect(() => {
    const handleWindowBlur = () =>
      logWindowActivity(ActivityTypesEnum.INTERVIEW_WINDOW_BLUR);
    const handleWindowFocus = () =>
      logWindowActivity(ActivityTypesEnum.INTERVIEW_WINDOW_FOCUS);
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && shouldForceFullscreen) {
        logWindowActivity(ActivityTypesEnum.INTERVIEW_FULLSCREEN_EXIT);
      }
    };

    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [shouldForceFullscreen]);

  return null;
};
