import { CheckIcon } from "@heroicons/react/24/outline";
import {
  InterviewPublic,
  isRecruitingModeProject,
  ProjectPublic,
  ResumeCollectionTypesEnum,
} from "app-types";
import { FC } from "react";

import { Link } from "ui";
import { SaveRetry } from "../interview/saveRetry";
import { ResumeUpload } from "./resumeUpload";

interface InterviewCompletedSectionProps {
  interview: InterviewPublic;
  project: ProjectPublic | null;
}

export const InterviewCompletedSection: FC<InterviewCompletedSectionProps> = ({
  interview,
  project,
}) => {
  const isRecruitingMode = project && isRecruitingModeProject(project);

  return (
    <div className="box-border p-8 text-center">
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mb-6">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
      <h1 className="text-3xl font-bold mb-4 text-gray-800">
        {interview.contact.first_name
          ? `Thanks, ${interview.contact.first_name}!`
          : "Interview completed"}
      </h1>
      <div className="mb-6 text-gray-800">
        {project?.outro_message ||
          `We really appreciate your responses. ${
            isRecruitingMode ? "You'll hear from the team soon." : ""
          }`}
        {project?.incentive_quantity ? (
          <>
            {`Within the next 3 days, we'll email your gift card to  `}
            <span className="font-semibold">{interview.contact.email}</span>
            {"."}
            {" Look out for an email from "}
            <span className="font-semibold">research@mail.alpharun.com</span>
            {" in your inbox (and spam folder)."}
          </>
        ) : null}
      </div>
      {isRecruitingMode &&
        project?.resume_collection_type ===
          ResumeCollectionTypesEnum.AFTER_INTERVIEW && (
          <ResumeUpload resumeCollectionType={project.resume_collection_type} />
        )}
      <SaveRetry
        isBackgroundMode={false}
        redirectUrl={project?.redirect_url || undefined}
      />
      {!project?.outro_message && (
        <div className="text-sm text-gray-600">
          {`Questions? Email us at `}
          <Link href="mailto:support@alpharun.com">support@alpharun.com</Link>
          {" or "}
          <Link href="https://www.alpharun.com">learn more about Alpharun</Link>
          {"."}
        </div>
      )}
    </div>
  );
};
